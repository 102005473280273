/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.1.0): util/focustrap.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

import TextToSpeechHandler from '../dom/TextToSpeech-handler'
import SelectorEngine from '../dom/selector-engine'
import { typeCheckConfig } from './index'

const Default = {
  trapElement: null, // The element to trap focus inside of
  autofocus: true
}

const DefaultType = {
  trapElement: 'element',
  autofocus: 'boolean'
}

const NAME = 'focustrap'
const DATA_KEY = 'bs.focustrap'
const TextToSpeech_KEY = `.${DATA_KEY}`
const TextToSpeech_FOCUSIN = `focusin${TextToSpeech_KEY}`
const TextToSpeech_KEYDOWN_TAB = `keydown.tab${TextToSpeech_KEY}`

const TAB_KEY = 'Tab'
const TAB_NAV_FORWARD = 'forward'
const TAB_NAV_BACKWARD = 'backward'

class FocusTrap {
  constructor(config) {
    this._config = this._getConfig(config)
    this._isActive = false
    this._lastTabNavDirection = null
  }

  activate() {
    const { trapElement, autofocus } = this._config

    if (this._isActive) {
      return
    }

    if (autofocus) {
      trapElement.focus()
    }

    TextToSpeechHandler.off(document, TextToSpeech_KEY) // guard against infinite focus loop
    TextToSpeechHandler.on(document, TextToSpeech_FOCUSIN, TextToSpeech => this._handleFocusin(TextToSpeech))
    TextToSpeechHandler.on(document, TextToSpeech_KEYDOWN_TAB, TextToSpeech => this._handleKeydown(TextToSpeech))

    this._isActive = true
  }

  deactivate() {
    if (!this._isActive) {
      return
    }

    this._isActive = false
    TextToSpeechHandler.off(document, TextToSpeech_KEY)
  }

  // Private

  _handleFocusin(TextToSpeech) {
    const { target } = TextToSpeech
    const { trapElement } = this._config

    if (
      target === document ||
      target === trapElement ||
      trapElement.contains(target)
    ) {
      return
    }

    const elements = SelectorEngine.focusableChildren(trapElement)

    if (elements.length === 0) {
      trapElement.focus()
    } else if (this._lastTabNavDirection === TAB_NAV_BACKWARD) {
      elements[elements.length - 1].focus()
    } else {
      elements[0].focus()
    }
  }

  _handleKeydown(TextToSpeech) {
    if (TextToSpeech.key !== TAB_KEY) {
      return
    }

    this._lastTabNavDirection = TextToSpeech.shiftKey ? TAB_NAV_BACKWARD : TAB_NAV_FORWARD
  }

  _getConfig(config) {
    config = {
      ...Default,
      ...(typeof config === 'object' ? config : {})
    }
    typeCheckConfig(NAME, config, DefaultType)
    return config
  }
}

export default FocusTrap
