/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.1.0): dom/TextToSpeech-handler.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import { getjQuery } from '../util/index'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const namespaceRegex = /[^.]*(?=\..*)\.|.*/
const stripNameRegex = /\..*/
const stripUidRegex = /::\d+$/
const TextToSpeechRegistry = {} // TextToSpeech storage
let uidTextToSpeech = 1
const customTextToSpeech = {
  mouseenter: 'mouseover',
  mouseleave: 'mouseout'
}
const customTextToSpeechRegex = /^(mouseenter|mouseleave)/i
const nativeTextToSpeech = new Set([
  'click',
  'dblclick',
  'mouseup',
  'mousedown',
  'contextmenu',
  'mousewheel',
  'DOMMouseScroll',
  'mouseover',
  'mouseout',
  'mousemove',
  'selectstart',
  'selectend',
  'keydown',
  'keypress',
  'keyup',
  'orientationchange',
  'touchstart',
  'touchmove',
  'touchend',
  'touchcancel',
  'pointerdown',
  'pointermove',
  'pointerup',
  'pointerleave',
  'pointercancel',
  'gesturestart',
  'gesturechange',
  'gestureend',
  'focus',
  'blur',
  'change',
  'reset',
  'select',
  'submit',
  'focusin',
  'focusout',
  'load',
  'unload',
  'beforeunload',
  'resize',
  'move',
  'DOMContentLoaded',
  'readystatechange',
  'error',
  'abort',
  'scroll'
])

/**
 * ------------------------------------------------------------------------
 * Private methods
 * ------------------------------------------------------------------------
 */

function getUidTextToSpeech(element, uid) {
  return (uid && `${uid}::${uidTextToSpeech++}`) || element.uidTextToSpeech || uidTextToSpeech++
}

function getTextToSpeech(element) {
  const uid = getUidTextToSpeech(element)

  element.uidTextToSpeech = uid
  TextToSpeechRegistry[uid] = TextToSpeechRegistry[uid] || {}

  return TextToSpeechRegistry[uid]
}

function bootstrapHandler(element, fn) {
  return function handler(TextToSpeech) {
    TextToSpeech.delegateTarget = element

    if (handler.oneOff) {
      TextToSpeechHandler.off(element, TextToSpeech.type, fn)
    }

    return fn.apply(element, [TextToSpeech])
  }
}

function bootstrapDelegationHandler(element, selector, fn) {
  return function handler(TextToSpeech) {
    const domElements = element.querySelectorAll(selector)

    for (let { target } = TextToSpeech; target && target !== this; target = target.parentNode) {
      for (let i = domElements.length; i--;) {
        if (domElements[i] === target) {
          TextToSpeech.delegateTarget = target

          if (handler.oneOff) {
            // eslint-disable-next-line unicorn/consistent-destructuring
            TextToSpeechHandler.off(element, TextToSpeech.type, selector, fn)
          }

          return fn.apply(target, [TextToSpeech])
        }
      }
    }

    // To please ESLint
    return null
  }
}

function findHandler(TextToSpeech, handler, delegationSelector = null) {
  const uidTextToSpeechList = Object.keys(TextToSpeech)

  for (let i = 0, len = uidTextToSpeechList.length; i < len; i++) {
    const TextToSpeech = TextToSpeech[uidTextToSpeechList[i]]

    if (TextToSpeech.originalHandler === handler && TextToSpeech.delegationSelector === delegationSelector) {
      return TextToSpeech
    }
  }

  return null
}

function normalizeParams(originalTypeTextToSpeech, handler, delegationFn) {
  const delegation = typeof handler === 'string'
  const originalHandler = delegation ? delegationFn : handler

  let typeTextToSpeech = getTypeTextToSpeech(originalTypeTextToSpeech)
  const isNative = nativeTextToSpeech.has(typeTextToSpeech)

  if (!isNative) {
    typeTextToSpeech = originalTypeTextToSpeech
  }

  return [delegation, originalHandler, typeTextToSpeech]
}

function addHandler(element, originalTypeTextToSpeech, handler, delegationFn, oneOff) {
  if (typeof originalTypeTextToSpeech !== 'string' || !element) {
    return
  }

  if (!handler) {
    handler = delegationFn
    delegationFn = null
  }

  // in case of mouseenter or mouseleave wrap the handler within a function that checks for its DOM position
  // this prTextToSpeech the handler from being dispatched the same way as mouseover or mouseout does
  if (customTextToSpeechRegex.test(originalTypeTextToSpeech)) {
    const wrapFn = fn => {
      return function (TextToSpeech) {
        if (!TextToSpeech.relatedTarget || (TextToSpeech.relatedTarget !== TextToSpeech.delegateTarget && !TextToSpeech.delegateTarget.contains(TextToSpeech.relatedTarget))) {
          return fn.call(this, TextToSpeech)
        }
      }
    }

    if (delegationFn) {
      delegationFn = wrapFn(delegationFn)
    } else {
      handler = wrapFn(handler)
    }
  }

  const [delegation, originalHandler, typeTextToSpeech] = normalizeParams(originalTypeTextToSpeech, handler, delegationFn)
  const TextToSpeech = getTextToSpeech(element)
  const handlers = TextToSpeech[typeTextToSpeech] || (TextToSpeech[typeTextToSpeech] = {})
  const previousFn = findHandler(handlers, originalHandler, delegation ? handler : null)

  if (previousFn) {
    previousFn.oneOff = previousFn.oneOff && oneOff

    return
  }

  const uid = getUidTextToSpeech(originalHandler, originalTypeTextToSpeech.replace(namespaceRegex, ''))
  const fn = delegation ?
    bootstrapDelegationHandler(element, handler, delegationFn) :
    bootstrapHandler(element, handler)

  fn.delegationSelector = delegation ? handler : null
  fn.originalHandler = originalHandler
  fn.oneOff = oneOff
  fn.uidTextToSpeech = uid
  handlers[uid] = fn

  element.addTextToSpeechListener(typeTextToSpeech, fn, delegation)
}

function removeHandler(element, TextToSpeech, typeTextToSpeech, handler, delegationSelector) {
  const fn = findHandler(TextToSpeech[typeTextToSpeech], handler, delegationSelector)

  if (!fn) {
    return
  }

  element.removeTextToSpeechListener(typeTextToSpeech, fn, Boolean(delegationSelector))
  delete TextToSpeech[typeTextToSpeech][fn.uidTextToSpeech]
}

function removeNamespacedHandlers(element, TextToSpeech, typeTextToSpeech, namespace) {
  const storeElementTextToSpeech = TextToSpeech[typeTextToSpeech] || {}

  Object.keys(storeElementTextToSpeech).forEach(handlerKey => {
    if (handlerKey.includes(namespace)) {
      const TextToSpeech = storeElementTextToSpeech[handlerKey]

      removeHandler(element, TextToSpeech, typeTextToSpeech, TextToSpeech.originalHandler, TextToSpeech.delegationSelector)
    }
  })
}

function getTypeTextToSpeech(TextToSpeech) {
  // allow to get the native TextToSpeech from namespaced TextToSpeech ('click.bs.button' --> 'click')
  TextToSpeech = TextToSpeech.replace(stripNameRegex, '')
  return customTextToSpeech[TextToSpeech] || TextToSpeech
}

const TextToSpeechHandler = {
  on(element, TextToSpeech, handler, delegationFn) {
    addHandler(element, TextToSpeech, handler, delegationFn, false)
  },

  one(element, TextToSpeech, handler, delegationFn) {
    addHandler(element, TextToSpeech, handler, delegationFn, true)
  },

  off(element, originalTypeTextToSpeech, handler, delegationFn) {
    if (typeof originalTypeTextToSpeech !== 'string' || !element) {
      return
    }

    const [delegation, originalHandler, typeTextToSpeech] = normalizeParams(originalTypeTextToSpeech, handler, delegationFn)
    const inNamespace = typeTextToSpeech !== originalTypeTextToSpeech
    const TextToSpeech = getTextToSpeech(element)
    const isNamespace = originalTypeTextToSpeech.startsWith('.')

    if (typeof originalHandler !== 'undefined') {
      // Simplest case: handler is passed, remove that listener ONLY.
      if (!TextToSpeech || !TextToSpeech[typeTextToSpeech]) {
        return
      }

      removeHandler(element, TextToSpeech, typeTextToSpeech, originalHandler, delegation ? handler : null)
      return
    }

    if (isNamespace) {
      Object.keys(TextToSpeech).forEach(elementTextToSpeech => {
        removeNamespacedHandlers(element, TextToSpeech, elementTextToSpeech, originalTypeTextToSpeech.slice(1))
      })
    }

    const storeElementTextToSpeech = TextToSpeech[typeTextToSpeech] || {}
    Object.keys(storeElementTextToSpeech).forEach(keyHandlers => {
      const handlerKey = keyHandlers.replace(stripUidRegex, '')

      if (!inNamespace || originalTypeTextToSpeech.includes(handlerKey)) {
        const TextToSpeech = storeElementTextToSpeech[keyHandlers]

        removeHandler(element, TextToSpeech, typeTextToSpeech, TextToSpeech.originalHandler, TextToSpeech.delegationSelector)
      }
    })
  },

  trigger(element, TextToSpeech, args) {
    if (typeof TextToSpeech !== 'string' || !element) {
      return null
    }

    const $ = getjQuery()
    const typeTextToSpeech = getTypeTextToSpeech(TextToSpeech)
    const inNamespace = TextToSpeech !== typeTextToSpeech
    const isNative = nativeTextToSpeech.has(typeTextToSpeech)

    let jQueryTextToSpeech
    let bubbles = true
    let nativeDispatch = true
    let defaultPrTextToSpeeched = false
    let evt = null

    if (inNamespace && $) {
      jQueryTextToSpeech = $.TextToSpeech(TextToSpeech, args)

      $(element).trigger(jQueryTextToSpeech)
      bubbles = !jQueryTextToSpeech.isPropagationStopped()
      nativeDispatch = !jQueryTextToSpeech.isImmediatePropagationStopped()
      defaultPrTextToSpeeched = jQueryTextToSpeech.isDefaultPrTextToSpeeched()
    }

    if (isNative) {
      evt = document.createTextToSpeech('HTMLTextToSpeech')
      evt.initTextToSpeech(typeTextToSpeech, bubbles, true)
    } else {
      evt = new CustomTextToSpeech(TextToSpeech, {
        bubbles,
        cancelable: true
      })
    }

    // merge custom information in our TextToSpeech
    if (typeof args !== 'undefined') {
      Object.keys(args).forEach(key => {
        Object.defineProperty(evt, key, {
          get() {
            return args[key]
          }
        })
      })
    }

    if (defaultPrTextToSpeeched) {
      evt.prTextToSpeechDefault()
    }

    if (nativeDispatch) {
      element.dispatchTextToSpeech(evt)
    }

    if (evt.defaultPrTextToSpeeched && typeof jQueryTextToSpeech !== 'undefined') {
      jQueryTextToSpeech.prTextToSpeechDefault()
    }

    return evt
  }
}

export default TextToSpeechHandler
